import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import {
  GroupAccountResponse,
  GroupProgramMembershipsMetadataResponse,
  V4GetGroupProgramMembershipsParams,
  V4GetGroupProgramMembershipsResponse,
} from './groups.backend.types';
import {
  DataDashboardFeedbackSegmentation,
  DataDashboardFeedbackType,
  OfficeHourSegmentedFeedback,
  OpportunitiesDataDashboard,
} from '@libs/src/data-dashboard/data-dashboard.interface';
import {
  AccountExperience,
  Experience,
} from '@libs/src/experience/experience.model';
import { Feedback } from '@libs/src/feedback/feedback';
import { createHttpParamsFromObject } from '@libs/src/http-params/http-params.helper';
import {
  Account,
  AccountGroupMembership,
} from '@libs/src/models/account.model';
import {
  DataSegmentSelection,
  Group,
  GroupCustomSegment,
  GroupCustomSegmentNode,
  GroupHiddenCustomSegment,
  GroupRole,
} from '@libs/src/models/group.model';
import { MatchPairing } from '@libs/src/models/match-pairing.model';
import {
  Match,
  MatchAggregateData,
  MatchConfig,
  MatchConfigCustomSegmentConfig,
  MatchCycle,
  MatchPairingUnpopulatedAccount,
  MatchSegment,
} from '@libs/src/models/match.interfaces';
import { PathwayPopulated } from '@libs/src/models/pathway.interface';
import {
  ProgramAccount,
  ProgramMembership,
  ProgramRole,
} from '@libs/src/models/program-membership.model';
import {
  MatchmakingProgramPhase,
  Program,
  ProgramPhase,
  ProgramTableData,
} from '@libs/src/models/program.model';
import { AccountMatchInfo } from '@libs/src/program/program-matches.helper';
import { GoalsDataResponse } from '@main-client/src/app/group-data-view-beta/group-data-view.helper';
import {
  AttributeMapping,
  AttributeMappingPostBody,
  AttributeMappingPutBody,
} from '@main-client/src/app/invite-csv/invite-csv-view/invite-csv-view.interface';
import {
  OpportunityTemplate,
  OpportunityTemplateConfig,
} from '@main-client/src/app/opportunity-templates-view/opportunity-template.model';
import { Opportunity } from '@main-client/src/app/opportunity/opportunity.model';
import { GetPreRegistrationsResponse } from '@main-client/src/app/sent-invitations/sent-invitations-view';

import { CustomInformation } from '../email-wysiwyg/email-wysiwyg.helper';
import { Survey } from '../models/survey.model';

const GROUPS_V2_API_URL = '/api/v2/groups';
const GROUPS_V3_API_URL = '/api/v3/groups';
const GROUPS_V4_API_URL = '/api/v4/groups';
const v3GroupsMatchPairingsURL = (groupId: Group['_id']) =>
  `${GROUPS_V3_API_URL}/${groupId}/match-pairings` as const;
const v3GroupsProgramsURL = (groupId: Group['_id']) =>
  `${GROUPS_V3_API_URL}/${groupId}/programs` as const;
const v3GroupsProgramMembershipsURL = (groupId: Group['_id']) =>
  `${GROUPS_V3_API_URL}/${groupId}/program-memberships` as const;
const v3GroupsOpportunityTemplatesURL = (groupId: Group['_id']) =>
  `${GROUPS_V3_API_URL}/${groupId}/opportunity-templates`;
const v4GroupsProgramMembershipsURL = (groupId: Group['_id']) =>
  `${GROUPS_V4_API_URL}/${groupId}/program-memberships` as const;

@Injectable({ providedIn: 'root' })
export class GroupsBackend {
  constructor(private readonly http: HttpClient) {}

  v2 = {
    getAccounts: (groupId: Group['_id'], params?: any) =>
      this.http.get<{ items: Account[]; total_count: number }>(
        `${GROUPS_V2_API_URL}/${groupId}/accounts`,
        {
          params,
        },
      ),
  };
  v3 = {
    deleteGroupMatchForProgram: (
      groupId: Group['_id'],
      matchId: Match['_id'],
      programId: Program['_id'],
    ) =>
      this.http.delete(
        `${v3GroupsProgramsURL(groupId)}/${programId}/matches/${matchId}`,
      ),
    deleteGroupMatchPairingForProgram: (
      groupId: Group['_id'],
      matchPairingId: MatchPairing['_id'],
      programId: Program['_id'],
    ) =>
      this.http.delete(
        `${v3GroupsProgramsURL(
          groupId,
        )}/${programId}/match-pairings/${matchPairingId}`,
      ),
    deleteGroupProgramSurvey: (
      groupId: Group['_id'],
      surveyId: Survey['_id'],
    ) =>
      this.http.delete(`${v3GroupsProgramsURL(groupId)}/surveys/${surveyId}`),
    deleteOpportunityTemplatePathway: (
      groupId: Group['_id'],
      opportunityTemplateName: string,
      pathwayId: PathwayPopulated['_id'],
    ) =>
      this.http.delete<{ opportunityTemplate: OpportunityTemplate }>(
        `${v3GroupsOpportunityTemplatesURL(
          groupId,
        )}/${opportunityTemplateName}/pathways/${pathwayId}`,
      ),
    deleteProgramMembership: (
      groupId: Group['_id'],
      programId: Program['_id'],
      membershipId: ProgramMembership['_id'],
    ) =>
      this.http.delete(
        `${v3GroupsProgramMembershipsURL(
          groupId,
        )}/${programId}/${membershipId}`,
      ),

    fetchNotificationRecipientsCount: (
      groupId: string,
      params: { [key: string]: any } = {},
    ) =>
      this.http.post<{ accountsToNotifyCount: number }>(
        `${GROUPS_V3_API_URL}/${groupId}/opportunity-recipients`,
        params,
      ),
    getAccount: (groupId: Group['_id'], params?: { email: string }) =>
      this.http.get(`${GROUPS_V3_API_URL}/${groupId}/account`, {
        params,
      }),
    getAccounts: (groupId: Group['_id'], params?: any) =>
      this.http.get<{ accounts: Account[]; total_count: number }>(
        `${GROUPS_V3_API_URL}/${groupId}/accounts`,
        { params },
      ),
    getEmailCommunicationsForGroup: (groupId: Group['_id']) =>
      this.http.get(`${GROUPS_V3_API_URL}/${groupId}/email-communications`),
    getExistingAccounts: (
      groupId: Group['_id'],
      params?: { emails: string[]; limit?: number; skip?: number },
    ) =>
      this.http.get<{
        accountStatuses: {
          existingAccounts: string[];
          nonExistingAccounts: string[];
        };
      }>(`${GROUPS_V3_API_URL}/${groupId}/accounts-exist`, {
        params,
      }),
    getGroupAttributeMappings: (groupId: Group['_id'], mappingType: string) =>
      this.http.get<{ attributeMappings: AttributeMapping[] }>(
        `${GROUPS_V3_API_URL}/${groupId}/attribute-mappings?mapping_type=${mappingType}`,
      ),
    getGroupMatchPairings: (groupId: Group['_id'], programId: Program['_id']) =>
      this.http.get<{ matchPairings: MatchPairing[] }>(
        `${v3GroupsProgramsURL(groupId)}/${programId}/match-pairings`,
      ),
    getGroupMatchPairingsData: (
      groupId: Group['_id'],
      programId: Program['_id'],
    ) =>
      this.http.get<{ matchPairingsData: MatchAggregateData[] }>(
        `${v3GroupsProgramsURL(groupId)}/${programId}/match-pairings-data`,
      ),
    getGroupProgram: (groupId: Group['_id'], programId: Program['_id']) =>
      this.http.get<ProgramTableData>(
        `${v3GroupsProgramsURL(groupId)}/${programId}`,
      ),
    getGroupProgramAccounts: (
      groupId: Group['_id'],
      programId: Program['_id'],
      params?: {
        keyword?: string;
        limit?: number;
        programRoles?: ProgramRole[];
        skip?: number;
      },
    ) =>
      this.http.get<{
        items: ProgramAccount[];
      }>(`${v3GroupsProgramsURL(groupId)}/${programId}/accounts`, {
        params,
      }),
    getGroupProgramCycles: (groupId: Group['_id'], programId: Program['_id']) =>
      this.http.get<{ programs: Program[] }>(
        `${v3GroupsProgramsURL(groupId)}/${programId}/cycles`,
      ),
    getGroupProgramMatches: (
      groupId: Group['_id'],
      programId: Program['_id'],
    ) =>
      this.http.get<{ matches: Match[] }>(
        `${v3GroupsProgramsURL(groupId)}/${programId}/matches`,
      ),
    getGroupProgramMatchesData: (
      groupId: Group['_id'],
      programId: Program['_id'],
    ) =>
      this.http.get<{ matchesData: MatchAggregateData[] }>(
        `${v3GroupsProgramsURL(groupId)}/${programId}/matches-data`,
      ),
    getGroupProgramMemberships: (
      groupId: Group['_id'],
      programId: Program['_id'],
      options = { params: {} },
    ) =>
      this.http.get<{
        programMemberships: ProgramMembership[];
        statusConfirmedCount: number;
        statusDeclinedCount: number;
        statusInactiveCount: number;
        statusInvitedCount: number;
        statusInvitedNoAccountCount: number;
        statusInvitedWithAccountCount: number;
        statusOptedOutCount: number;
        statusPendingCount: number;
        statusReadyToInviteCount: number;
        totalCount: number;
      }>(`${v3GroupsProgramMembershipsURL(groupId)}/${programId}`, options),
    getGroupProgramMembershipsSearch: (
      groupId: Group['_id'],
      programId: Program['_id'],
      options = { params: {} },
    ) =>
      this.http.get(
        `${v3GroupsProgramMembershipsURL(groupId)}/${programId}/search`,
        options,
      ),
    getGroupProgramSurveys: (
      groupId: Group['_id'],
      params?: { [param: string]: string[] | string },
    ) =>
      this.http.get<{ surveys: Survey[] }>(
        `${v3GroupsProgramsURL(groupId)}/surveys`,
        {
          params,
        },
      ),
    getGroupPrograms: (
      groupId: Group['_id'],
      params?: { limit?: string; skip?: string; status?: Program['status'] },
    ) =>
      this.http.get<{
        programsData: ProgramTableData[];
        totalCount: number;
      }>(`${v3GroupsProgramsURL(groupId)}`, { params }),
    getGroups: (params: any) => this.http.get(GROUPS_V3_API_URL, { params }),
    getOpportunityParticipantFeedbackData: (
      groupId: Group['_id'],
      opportunityId: Opportunity['_id'],
    ) =>
      this.http.get(
        `${GROUPS_V3_API_URL}/${groupId}/opportunities/${opportunityId}/participant-feedback-data`,
      ),
    getOpportunityRsvpsXlsx: (
      group: Group,
      opportunityId: Opportunity['_id'],
    ) =>
      this.http.get(
        `${GROUPS_V3_API_URL}/${group._id}/opportunities/${opportunityId}/opportunity-rsvps-xlsx`,
      ),
    getOpportunityTemplate: (
      groupId: Group['_id'],
      opportunityTemplateName: string,
    ) =>
      this.http.get<{ opportunityTemplate: OpportunityTemplate }>(
        `${v3GroupsOpportunityTemplatesURL(
          groupId,
        )}/${opportunityTemplateName}`,
      ),
    getOpportunityTemplates: (groupId: Group['_id']) =>
      this.http.get<{ opportunityTemplates: OpportunityTemplateConfig }>(
        `${v3GroupsOpportunityTemplatesURL(groupId)}`,
      ),
    patchGroupEmailCommunications: (
      groupId: Group['_id'],
      templateName: string,
      body = {},
    ) =>
      this.http.patch(
        `${GROUPS_V3_API_URL}/${groupId}/email-communications/${templateName}`,
        body,
      ),
    patchGroupMatchPairing: (
      groupId: Group['_id'],
      matchPairingId: MatchPairing['_id'],
      update: {
        accounts?: MatchPairingUnpopulatedAccount['accounts'];
        lock?: boolean;
      },
    ) =>
      this.http.patch<{
        matchPairing: MatchPairing;
      }>(`${v3GroupsMatchPairingsURL(groupId)}/${matchPairingId}`, update),
    patchGroupMatchPairings: (
      groupId: Group['_id'],
      matchPairingIds: Array<MatchPairing['_id']>,
      update: { lock: boolean },
    ) =>
      this.http.patch(
        `${GROUPS_V3_API_URL}/${groupId}/match-pairings`,
        update,
        { params: { matchPairingIds } },
      ),
    patchGroupProgram: (
      groupId: Group['_id'],
      programId: Program['_id'],
      updateBody: Program,
    ) =>
      this.http.patch<{ program: Program }>(
        `${v3GroupsProgramsURL(groupId)}/${programId}`,
        updateBody,
      ),
    patchGroupProgramMemberships: (
      groupId: Group['_id'],
      programId: Program['_id'],
      programMembershipIds: Array<ProgramMembership['_id']>,
      update: Pick<
        ProgramMembership,
        'match_exclusions' | 'program_preferences' | 'status'
      >,
    ) =>
      this.http.patch<{ programMemberships: ProgramMembership[] }>(
        `${v3GroupsProgramMembershipsURL(groupId)}/${programId}`,
        update,
        { params: { programMembershipIds } },
      ),
    patchGroupProgramPhaseMatchmaking: (
      groupId: Group['_id'],
      programId: Program['_id'],
      update: Partial<MatchmakingProgramPhase> & {
        'email.body'?: string;
        'email.subject'?: string;
      },
    ) =>
      this.http.patch<{ program: Program }>(
        `${v3GroupsProgramsURL(groupId)}/${programId}/phases/matchmaking`,
        update,
      ),
    patchGroupProgramSurvey: (
      groupId: Group['_id'],
      surveyId: Survey['_id'],
      survey: Partial<Survey>,
    ) =>
      this.http.patch<{ survey: Survey }>(
        `${v3GroupsProgramsURL(groupId)}/surveys/${surveyId}`,
        survey,
      ),
    patchOpportunityTemplate: (
      groupId: Group['_id'],
      opportunityTemplateName: string,
      opportunityTemplateBody: OpportunityTemplate,
    ) =>
      this.http.put<{ opportunityTemplate: OpportunityTemplate }>(
        `${v3GroupsOpportunityTemplatesURL(
          groupId,
        )}/${opportunityTemplateName}`,
        opportunityTemplateBody,
      ),
    patchOpportunityTemplatePathway: (
      groupId: Group['_id'],
      opportunityTemplateName: string,
      pathwayId: PathwayPopulated['_id'],
    ) =>
      this.http.patch<{ opportunityTemplate: OpportunityTemplate }>(
        `${v3GroupsOpportunityTemplatesURL(
          groupId,
        )}/${opportunityTemplateName}/pathways/${pathwayId}`,
        {},
      ),
    patchProgramMatchStatus: (
      groupId: Group['_id'],
      programId: Program['_id'],
      matchId: Match['_id'],
      status: ProgramMembership['matches'][number]['accounts'][number]['status'],
    ) =>
      this.http.patch(
        `${v3GroupsProgramsURL(groupId)}/${programId}/matches/${matchId}`,
        { status },
      ),
    postDirectorySyncBulkInviteUpload: (groupId: Group['_id']) =>
      this.http.post<void>(
        `${GROUPS_V3_API_URL}/${groupId}/directory-sync/bulk-invitations-csv`,
        {},
      ),
    postGroup: (groupBody: Partial<Group>, templateSlug: string) =>
      this.http.post<{ group: Group }>(`${GROUPS_V3_API_URL}`, {
        templateSlug,
        group: groupBody,
      }),
    postGroupAttributeMapping: (
      attributeMappingPostBody: AttributeMappingPostBody,
      groupId: Group['_id'],
    ) =>
      this.http.post<{ attributeMapping: AttributeMapping }>(
        `${GROUPS_V3_API_URL}/${groupId}/attribute-mappings`,
        attributeMappingPostBody,
      ),
    postGroupMatchPairings: (
      groupId: Group['_id'],
      programId: Program['_id'],
      options: {
        emails?: string[];
        status: Array<ProgramMembership['status']>;
      },
    ) =>
      this.http.post(
        `${v3GroupsProgramsURL(groupId)}/${programId}/match-pairings`,
        options,
      ),
    postGroupProgram: (groupId: Group['_id'], program: Program) =>
      this.http.post(`${v3GroupsProgramsURL(groupId)}`, program),
    postGroupProgramInvitation: (
      groupId: Group['_id'],
      programId: Program['_id'],
      body: {
        accountIds?: Array<Account['_id']>;
        customInformation: CustomInformation;
        emails: Array<Account['email']>;
        hasAccount?: boolean;
        roles: ProgramRole[];
        status?: Array<Account['status']>;
      },
    ) =>
      this.http.post(
        `${v3GroupsProgramsURL(groupId)}/${programId}/program-invitation`,
        body,
      ),
    postGroupProgramMatchPairing: (
      groupId: Group['_id'],
      programId: Program['_id'],
      body: { accountIds: Array<Account['_id']>; lock?: boolean },
    ) =>
      this.http.post<{ matchPairing: MatchPairing }>(
        `${v3GroupsProgramsURL(groupId)}/${programId}/match-pairing`,
        body,
      ),
    postGroupProgramMatches: (
      groupId: Group['_id'],
      programId: Program['_id'],
    ) =>
      this.http.post(
        `${v3GroupsProgramsURL(groupId)}/${programId}/create-matches`,
        {},
      ),
    postGroupProgramMatchmaking: (
      groupId: Group['_id'],
      programId: Program['_id'],
      body: {
        customInformation: CustomInformation;
        matchIds: Array<Match['_id']>;
      },
    ) =>
      this.http.post(
        `${v3GroupsProgramsURL(groupId)}/${programId}/program-matchmaking`,
        body,
      ),
    postGroupProgramMembershipForAccount: (
      groupId: Group['_id'],
      programId: Program['_id'],
      accountId: Account['_id'],
      programMembership: {
        program_preferences?: ProgramMembership['program_preferences'];
        status?: ProgramMembership['status'];
      } = {},
    ) =>
      this.http.post<{ programMembership: ProgramMembership }>(
        `${v3GroupsProgramMembershipsURL(
          groupId,
        )}/${programId}/accounts/${accountId}`,
        programMembership,
      ),
    postGroupProgramMembershipForEmail: (
      groupId: Group['_id'],
      programId: Program['_id'],
      programMembership: {
        email: Account['email'];
        program_preferences: ProgramMembership['program_preferences'];
      },
    ) =>
      this.http.post(
        `${v3GroupsProgramMembershipsURL(groupId)}/${programId}`,
        programMembership,
      ),
    postGroupProgramSurvey: (groupId: Group['_id'], survey: Partial<Survey>) =>
      this.http.post<{ survey: Survey }>(
        `${v3GroupsProgramsURL(groupId)}/surveys`,
        survey,
      ),
    postProgramPhaseResend: (
      groupId: Group['_id'],
      programId: Program['_id'],
      phaseId: ProgramPhase['_id'],
      body: {
        customInformation?: CustomInformation;
        matchId: AccountMatchInfo['match']['_id'];
        sendTo: AccountMatchInfo['match']['_id'];
      },
    ) =>
      this.http.post<{ message: string }>(
        `${GROUPS_V3_API_URL}/${groupId}/programs/${programId}/phase/${phaseId}/resend`,
        body,
      ),
    putGroupAttributeMapping: (
      attributeMapping: AttributeMappingPutBody,
      attributeMappingId: string,
      groupId: Group['_id'],
    ) =>
      this.http.put<{ attributeMapping: AttributeMapping }>(
        `${GROUPS_V3_API_URL}/${groupId}/attribute-mappings/${attributeMappingId}`,
        attributeMapping,
      ),
    putGroupEmailCommunications: (groupId: Group['_id'], body = {}) =>
      this.http.put(
        `${GROUPS_V3_API_URL}/${groupId}/email-communications`,
        body,
      ),
    sendPreviewEmail: (
      groupId: Group['_id'],
      templateName: string,
      variationName = 'default',
      testData = {},
    ) =>
      this.http.post(
        `${GROUPS_V3_API_URL}/${groupId}/email-preview/${templateName}`,
        {
          testData,
          variationName,
        },
      ),
    sendProgressSummaryEmail: (
      body: { accountId: Account['_id'] },
      groupId: Group['_id'],
      programId: Program['_id'],
    ) =>
      this.http.post<{ message: string }>(
        `${v3GroupsProgramsURL(groupId)}/${programId}/send-progress-summary`,
        body,
      ),
  };
  v4 = {
    getGroupMatchCalendarUrl: (groupId: Group['_id'], matchId: Match['_id']) =>
      this.http.get<{
        generated: boolean;
        url: string;
      }>(`${GROUPS_V4_API_URL}/${groupId}/match/${matchId}/calendar-url`),
    getGroupMembers: (
      groupId: Group['_id'],
      params: { limit?: number; query: string; skip?: number },
    ) =>
      this.http.get<GroupAccountResponse>(
        `${GROUPS_V4_API_URL}/${groupId}/accounts`,
        {
          params,
        },
      ),
    getGroupProgramMemberships: (
      groupId: Group['_id'],
      programId: Program['_id'],
      params: V4GetGroupProgramMembershipsParams = { limit: 10, skip: 0 },
    ) =>
      this.http.get<V4GetGroupProgramMembershipsResponse>(
        `${v4GroupsProgramMembershipsURL(groupId)}/${programId}`,
        {
          params: { ...params },
        },
      ),
    getGroupProgramMembershipsMetadata: (
      groupId: Group['_id'],
      programId: Program['_id'],
    ) =>
      this.http.get<GroupProgramMembershipsMetadataResponse>(
        `${v4GroupsProgramMembershipsURL(groupId)}/${programId}/metadata`,
      ),
  };

  addGroupMatchSegment(
    group: Group,
    matchConfig: MatchConfig,
    matchSegment: MatchSegment,
  ) {
    return this.http.post(
      `${this.groupMatchSegmentApiUrl(group, matchConfig)}`,
      matchSegment,
    );
  }

  deleteCustomSegments(group: Group, segment: GroupCustomSegment) {
    return this.http.delete<GroupCustomSegment[]>(
      `${this.groupCustomSegmentsApiUrl(group)}/${segment._id}`,
    );
  }

  deleteCustomSegmentsNode(
    group: Group,
    customSegment: GroupCustomSegment,
    node: GroupCustomSegmentNode,
  ) {
    return this.http.delete(
      `${this.groupCustomSegmentsApiUrl(group)}/${customSegment._id}/nodes/${
        node._id
      }`,
    );
  }

  deleteGroupMatchSegment(
    group: Group,
    matchConfig: MatchConfig,
    matchSegment: MatchSegment,
  ) {
    return this.http.delete(
      `${this.groupMatchSegmentApiUrl(group, matchConfig)}/${matchSegment._id}`,
    );
  }

  deleteHiddenSegments(group: Group, segment: GroupHiddenCustomSegment) {
    return this.http.delete<GroupHiddenCustomSegment[]>(
      `${this.groupHiddenSegmentsApiUrl(group)}/${segment._id}`,
    );
  }

  deleteHiddenSegmentsNode(
    group: Group,
    customSegment: GroupHiddenCustomSegment,
    node: GroupCustomSegmentNode,
  ) {
    return this.http.delete<GroupHiddenCustomSegment[]>(
      `${this.groupHiddenSegmentsApiUrl(group)}/${customSegment._id}/nodes/${
        node._id
      }`,
    );
  }

  deleteRoles(group: Group, role: GroupRole) {
    return this.http.delete(`${this.groupsApiUrl(group)}/roles/${role._id}`);
  }

  getAccounts(group: Group, params: any) {
    return this.http.get(`${this.groupsApiUrl(group)}/accounts`, { params });
  }

  getAccountsExperiences(
    group: Group,
    account: Account,
    params: { docTypes?: string[]; endDate?: string; startDate?: string },
  ) {
    return this.http.get<{
      experiences: Experience[];
      totalCount: number;
    }>(`${this.groupsApiUrl(group)}/accounts/${account._id}/experiences`, {
      params,
    });
  }

  getDataConnectionsLeaderBoard(
    groupId: Group['_id'],
    {
      endDate,
      limit,
      skip,
      startDate,
    }: { endDate: Date; limit: number; skip: number; startDate: Date },
  ) {
    return this.http.get(
      `api/v3/groups/${groupId}/data/connections-leader-board`,
      {
        params: {
          endDate: endDate.toDateString(),
          limit: `${limit}`,
          skip: `${skip}`,
          startDate: startDate.toDateString(),
        },
      },
    );
  }

  getDataConnectionsMade(
    groupId: Group['_id'],
    { endDate, startDate }: { endDate: Date; startDate: Date },
  ) {
    return this.http.get(`api/v3/groups/${groupId}/data/connections-made`, {
      params: {
        endDate: endDate.toDateString(),
        startDate: startDate.toDateString(),
      },
    });
  }

  getDataGoals(
    groupId: Group['_id'],
    filters: { [path: string]: string[] | string } = {
      roles: [],
      useExactMatch: 'false',
    },
  ) {
    return this.http.get<GoalsDataResponse>(
      `api/v3/groups/${groupId}/data/goals`,
      {
        params: filters,
      },
    );
  }

  getDataGroupProgramInitialResponseRate(
    groupId: Group['_id'],
    {
      startDateMax = null,
      startDateMin = null,
    }: { startDateMax?: Date; startDateMin?: Date } = {},
  ) {
    let params = new HttpParams();
    if (startDateMin) {
      params = params.set('startDateMin', startDateMin.toISOString());
    }
    if (startDateMax) {
      params = params.set('startDateMax', startDateMax.toISOString());
    }
    return this.http.get<{
      totalUniqueAccounts: { total: number };
      totalUniqueAccountsWithResponse: { total: number };
    }>(`/api/v3/groups/${groupId}/data/group-program-initial-response-rate`, {
      params,
    });
  }

  getDataGroupProgramMatches(
    groupId: Group['_id'],
    {
      startDateMax = null,
      startDateMin = null,
    }: { startDateMax?: Date; startDateMin?: Date } = {},
  ) {
    let params = new HttpParams();
    if (startDateMin) {
      params = params.set('startDateMin', startDateMin.toISOString());
    }
    if (startDateMax) {
      params = params.set('startDateMax', startDateMax.toISOString());
    }
    return this.http.get<{ total: number }>(
      `/api/v3/groups/${groupId}/data/group-program-matches`,
      { params },
    );
  }

  getDataInterests(
    groupId: Group['_id'],
    filters: { [path: string]: string[] | string } = {
      roles: [],
      useExactMatch: 'false',
    },
  ) {
    return this.http.get(`api/v3/groups/${groupId}/data/interests`, {
      params: filters,
    });
  }

  getDataMessagesSent(
    groupId: Group['_id'],
    { endDate, startDate }: { endDate: Date; startDate: Date },
  ) {
    return this.http.get(`api/v3/groups/${groupId}/data/messages-sent`, {
      params: {
        endDate: endDate.toDateString(),
        startDate: startDate.toDateString(),
      },
    });
  }

  getDataOpportunities(
    groupId: Group['_id'],
    {
      endDate,
      limit,
      skip,
      startDate,
    }: { endDate: Date; limit: number; skip: number; startDate: Date },
  ) {
    return this.http.get<OpportunitiesDataDashboard>(
      `api/v3/groups/${groupId}/data/opportunities`,
      {
        params: {
          endDate: endDate.toDateString(),
          limit: `${limit}`,
          skip: `${skip}`,
          startDate: startDate.toDateString(),
        },
      },
    );
  }

  getDataOpportunitySegmentedFeedback(
    groupId: Group['_id'],
    {
      endDate,
      feedbackSentiment,
      limit,
      skip,
      startDate,
      type,
    }: {
      endDate: Date;
      feedbackSentiment: DataDashboardFeedbackSegmentation[];
      limit: number;
      skip: number;
      startDate: Date;
      type: DataDashboardFeedbackType;
    },
  ) {
    return this.http.get<OfficeHourSegmentedFeedback>(
      `api/v3/groups/${groupId}/data/opportunities/segmented-feedback`,
      {
        params: {
          feedbackSentiment,
          type,
          endDate: endDate.toDateString(),
          limit: `${limit}`,
          skip: `${skip}`,
          startDate: startDate.toDateString(),
        },
      },
    );
  }

  getDataOpportunitySegmentedFeedbackCsv(
    groupId: Group['_id'],
    {
      endDate,
      feedbackSentiment,
      startDate,
      type,
    }: {
      endDate: Date;
      feedbackSentiment: DataDashboardFeedbackSegmentation[];
      startDate: Date;
      type: DataDashboardFeedbackType;
    },
  ) {
    return this.http.post<void>(
      `api/v3/groups/${groupId}/data/opportunities/feedback-csv`,
      {
        feedbackSentiment,
        type,
        endDate: endDate.toDateString(),
        startDate: startDate.toDateString(),
      },
    );
  }

  getDataOptedIntoMatchmaking(groupId: Group['_id']) {
    return this.http.get(
      `api/v3/groups/${groupId}/data/opted-into-matchmaking`,
    );
  }

  getDataRoles(groupId: Group['_id']) {
    return this.http.get(`api/v3/groups/${groupId}/data/roles`);
  }

  getDataSegmentSelections(
    groupId: Group['_id'],
    customSegmentId: GroupCustomSegment['_id'],
    extraParams?: { [key: string]: boolean | string },
  ) {
    return this.http.get<DataSegmentSelection>(
      `api/v3/groups/${groupId}/data/segment-selections`,
      {
        params: { customSegmentId, ...extraParams },
      },
    );
  }

  getDataStatusActive(groupId: Group['_id']) {
    return this.http.get(`api/v3/groups/${groupId}/data/status-active`);
  }

  getFilesIntroductionsCsv(
    group: Group,
    params?: { matchCycleId?: MatchCycle['_id'] },
  ) {
    return this.http.get(
      `${this.groupsApiUrl(group)}/files/introductions-csv`,
      { params },
    );
  }

  getFilesMembersCsv(group: Group) {
    return this.http.get(`${this.groupsApiUrl(group)}/files/members-csv`);
  }

  getFilesPreregistrationsCsv(group: Group) {
    return this.http.get(
      `${this.groupsApiUrl(group)}/files/preregistrations-csv`,
    );
  }

  getGroup(group: Group) {
    return this.http.get<Group>(`${this.groupsApiUrl(group)}`);
  }

  getGroupExperiences(
    group: Group,
    params: {
      docTypes?: string[];
      endDate?: string;
      limit?: string;
      segmentId?: string;
      skip?: string;
      startDate?: string;
    },
  ) {
    return this.http.get<{
      groupExperiences: AccountExperience[];
    }>(`${this.groupsApiUrl(group)}/experiences`, { params });
  }

  getIntroductions(group: Group, params?: any) {
    return this.http.get(`${this.groupsApiUrl(group)}/introductions`, {
      params,
    });
  }

  getIntroductionsDataPairsBreakdown(
    group: Group,
    matchCycleIds: Array<MatchCycle['_id']>,
  ) {
    return this.http.get<{
      summary: {
        breakdownByCustomSegments: Array<{
          _id: GroupCustomSegment['_id'];
          nodePairs: { _id: string[]; pairsCount: number };
        }>;
        breakdownByRolePairs: Array<{ _id: string[]; pairsCount: number }>;
        totalCount: { all: number };
      };
    }>(`${this.groupsApiUrl(group)}/introductions-data/pairs-breakdown`, {
      params: { matchCycleIds },
    });
  }

  getIntroductionsDataSummary(
    group: Group,
    matchCycleIds: Array<MatchCycle['_id']>,
  ) {
    return this.http.get<{
      summary: {
        breakdownByFeedbackTypes: Array<{
          _id: Feedback['feedback_type'];
          count: number;
        }>;
        breakdownByMatchStatus: Array<{
          _id: Match['accounts'][number]['status'];
          count: number;
        }>;
        totalCount: Record<'all', number>;
      };
    }>(`${this.groupsApiUrl(group)}/introductions-data/summary`, {
      params: { matchCycleIds },
    });
  }

  getIntroductionsFeedbackCsv(
    group: Group,
    feedbackType: Feedback['feedback_type'],
    matchCycleIds: Array<MatchCycle['_id']>,
  ) {
    return this.http.get(
      `${this.groupsApiUrl(group)}/files/introductions-feedback-data-csv`,
      { params: { feedbackType, matchCycleIds } },
    );
  }

  getIntroductionsFeedbackData(
    group: Group,
    feedbackType: Feedback['feedback_type'],
    matchCycleIds: Array<MatchCycle['_id']>,
  ) {
    return this.http.get(
      `${this.groupsApiUrl(group)}/introductions-feedback-data`,
      { params: { feedbackType, matchCycleIds } },
    );
  }

  getIntroductionsFeedbackSentiment(
    group: Group,
    feedbackIds: Array<Feedback['_id']>,
  ) {
    return this.http.get(
      `${this.groupsApiUrl(group)}/introductions-feedback-sentiment`,
      { params: { feedbackIds } },
    );
  }

  getMatchConfigs(group: Group, params?: any) {
    return this.http.get<{ count: number; items: MatchConfig[] }>(
      `${this.groupsApiUrl(group)}/match_configs`,
      { params },
    );
  }

  getMatchCycle(groupId: Group['_id'], matchCycleId: MatchCycle['_id']) {
    return this.http.get(
      `api/v3/groups/${groupId}/match-cycles/${matchCycleId}`,
      { params: { matchCycleId } },
    );
  }

  getMatchCyclePreview(group: Group) {
    return this.http.get(`${this.groupsApiUrl(group)}/match_cycles/preview`);
  }

  getMatchCycles(
    group: Group,
    {
      cycleEndMin = null,
      cycleStartMax = null,
      limit = null,
      matchConfig = null,
      sort = null,
    }: {
      cycleEndMin?: string;
      cycleStartMax?: string;
      limit?: number;
      matchConfig?: string;
      sort?: string;
    } = {},
  ) {
    const params = createHttpParamsFromObject({
      cycleEndMin,
      cycleStartMax,
      limit,
      matchConfig,
      sort,
    });
    return this.http.get<{ count: number; items: MatchCycle[] }>(
      `${this.groupsApiUrl(group)}/match_cycles`,
      {
        params,
      },
    );
  }

  getOpportunityParticipantFeedbackDataCsv(
    group: Group,
    feedbackType: Feedback['feedback_type'],
    opportunityId: Opportunity['_id'],
  ) {
    return this.http.get(
      `${GROUPS_V3_API_URL}/${group._id}/opportunities/${opportunityId}/participant-feedback-data-csv`,
      { params: { feedbackType, opportunityId } },
    );
  }

  getPreregistrationsBounced(group: Group, params: any) {
    return this.http.get(
      `${this.groupsApiUrl(group)}/pre-registrations/bounced`,
      {
        params,
      },
    );
  }

  getPreregistrationsClaimed(group: Group, params: any) {
    return this.http.get<GetPreRegistrationsResponse>(
      `${this.groupsApiUrl(group)}/pre-registrations/claimed`,
      {
        params,
      },
    );
  }

  getPreregistrationsPending(group: Group, params: any) {
    return this.http.get<GetPreRegistrationsResponse>(
      `${this.groupsApiUrl(group)}/pre-registrations/pending`,
      {
        params,
      },
    );
  }

  groupCustomSegmentsApiUrl(group: Group) {
    return `${this.groupsApiUrl(group)}/custom_segments`;
  }

  groupHiddenSegmentsApiUrl(group: Group) {
    return `${this.groupsApiUrl(group)}/hidden_custom_segments`;
  }

  groupMatchSegmentApiUrl(group: Group, matchConfig: MatchConfig) {
    return `${this.groupsApiUrl(group)}/match_configs/${
      matchConfig._id
    }/match_segments`;
  }

  groupsApiUrl(group: Group) {
    return `/api/v2/groups/${group._id || group.slug}`;
  }

  patchCustomSegments(
    group: Group,
    segment: GroupCustomSegment,
    update: GroupCustomSegment,
  ) {
    return this.http.patch<GroupCustomSegment[]>(
      `${this.groupCustomSegmentsApiUrl(group)}/${segment._id}`,
      update,
    );
  }

  patchCustomSegmentsNode(
    group: Group,
    customSegment: GroupCustomSegment,
    node: GroupCustomSegmentNode,
    body: GroupCustomSegmentNode,
  ) {
    return this.http.patch(
      `${this.groupCustomSegmentsApiUrl(group)}/${customSegment._id}/nodes/${
        node._id
      }`,
      body,
    );
  }

  patchGroup(group: Group, update: any) {
    return this.http.patch<Group>(`${this.groupsApiUrl(group)}`, update);
  }

  patchGroupMatchConfig(
    group: Group,
    matchConfig: MatchConfig,
    update: MatchConfig,
    options?: { matchAll?: string },
  ) {
    const params = { match_all: options?.matchAll };
    return this.http.patch<MatchConfig>(
      `${this.groupsApiUrl(group)}/match_configs/${matchConfig._id}`,
      update,
      { params },
    );
  }

  patchGroupMatchCycle(
    group: Group,
    matchCycle: MatchCycle,
    update: MatchCycle,
  ) {
    return this.http.patch(
      `${this.groupsApiUrl(group)}/match_cycles/${matchCycle._id}`,
      update,
    );
  }

  patchGroupMatchSegment(
    group: Group,
    matchConfig: MatchConfig,
    matchSegment: MatchSegment,
    update: MatchSegment,
  ) {
    return this.http.patch(
      `${this.groupMatchSegmentApiUrl(group, matchConfig)}/${matchSegment._id}`,
      update,
    );
  }

  patchHiddenSegments(
    group: Group,
    segment: GroupHiddenCustomSegment,
    update: GroupHiddenCustomSegment,
  ) {
    return this.http.patch<GroupHiddenCustomSegment[]>(
      `${this.groupHiddenSegmentsApiUrl(group)}/${segment._id}`,
      update,
    );
  }

  patchHiddenSegmentsNode(
    group: Group,
    customSegment: GroupHiddenCustomSegment,
    node: GroupCustomSegmentNode,
    body: GroupCustomSegmentNode,
  ) {
    return this.http.patch<GroupHiddenCustomSegment[]>(
      `${this.groupHiddenSegmentsApiUrl(group)}/${customSegment._id}/nodes/${
        node._id
      }`,
      body,
    );
  }

  postCustomSegments(group: Group, segment: GroupCustomSegment) {
    return this.http.post<GroupCustomSegment[]>(
      `${this.groupCustomSegmentsApiUrl(group)}`,
      segment,
    );
  }

  postCustomSegmentsNode(
    group: Group,
    customSegment: GroupCustomSegment,
    body: { name: string },
  ) {
    return this.http.post(
      `${this.groupCustomSegmentsApiUrl(group)}/${customSegment._id}/nodes`,
      body,
    );
  }

  postHiddenSegments(group: Group, segment: GroupHiddenCustomSegment) {
    return this.http.post<GroupHiddenCustomSegment[]>(
      `${this.groupHiddenSegmentsApiUrl(group)}`,
      segment,
    );
  }

  postHiddenSegmentsNode(
    group: Group,
    customSegment: GroupHiddenCustomSegment,
    body: { name: string },
  ) {
    return this.http.post<GroupHiddenCustomSegment[]>(
      `${this.groupHiddenSegmentsApiUrl(group)}/${customSegment._id}/nodes`,
      body,
    );
  }

  postMatchConfig(group: Group, matchConfig: MatchConfig) {
    return this.http.post(`${this.groupsApiUrl(group)}/match_configs`, {
      newMatchConfig: matchConfig,
    });
  }

  postPreregistrationSetup(group: Group, preregistration: any) {
    return this.http.post(
      `${this.groupsApiUrl(group)}/pre-registrations/setup`,
      preregistration,
    );
  }

  postRoles(group: Group, role: GroupRole) {
    return this.http.post(`${this.groupsApiUrl(group)}/roles`, role);
  }

  putAccountsGroup(
    group: Group,
    account: Account,
    membership: AccountGroupMembership,
  ) {
    return this.http.put(
      `${this.groupsApiUrl(group)}/accounts/${account._id}`,
      membership,
    );
  }

  putAccountsGroups(
    group: Group,
    account: Account,
    update: Partial<AccountGroupMembership>,
  ) {
    return this.http.put(
      `${this.groupsApiUrl(group)}/accounts/${account._id}`,
      update,
    );
  }

  putAccountsPendingGroup(group: Group, account: Account, update: any) {
    return this.http.put(
      `${this.groupsApiUrl(group)}/accounts/${account._id}/pending_groups`,
      update,
    );
  }

  putMatchConfigCustomSegmentsConfigs(
    group: Group,
    matchConfig: MatchConfig,
    customSegmentConfig: MatchConfigCustomSegmentConfig,
    update: {
      edges?: MatchConfigCustomSegmentConfig['edges'];
      match_type?: MatchConfigCustomSegmentConfig['match_type'];
    },
  ) {
    return this.http.patch(
      `${this.groupsApiUrl(group)}/match_configs/${
        matchConfig._id
      }/custom_segment_configs/${customSegmentConfig._id}`,
      update,
    );
  }

  putMatchConfigHiddenCustomSegmentsConfigs(
    group: Group,
    matchConfig: MatchConfig,
    hiddenCustomSegmentConfig: MatchConfigCustomSegmentConfig,
    update: {
      edges?: MatchConfigCustomSegmentConfig['edges'];
      match_type?: MatchConfigCustomSegmentConfig['match_type'];
    },
  ) {
    return this.http.patch<MatchConfigCustomSegmentConfig>(
      `${this.groupsApiUrl(group)}/match_configs/${
        matchConfig._id
      }/hidden_custom_segment_configs/${hiddenCustomSegmentConfig._id}`,
      update,
    );
  }

  putRoles(group: Group, role: GroupRole, update: GroupRole) {
    return this.http.put(
      `${this.groupsApiUrl(group)}/roles/${role._id}`,
      update,
    );
  }

  getHtmlForTipTapPreview(
    proseMirrorJsonString: string,
  ): Observable<{ htmlString: string }> {
    return this.http.post<{ htmlString: string }>(
      `${GROUPS_V4_API_URL}/get-tiptap-preview`,
      {
        proseMirrorJsonString,
      },
    );
  }
}
