import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import type { TrackParams } from '@segment/analytics-node';
import { Observable, of } from 'rxjs';

import {
  AUTH_TOKEN_KEY,
  LocalStorageService,
} from '@main-client/src/app/core/local-storage.service';
import { WindowRefService } from '@main-client/src/app/window-ref.service';

import { environment } from '@libs/src/environments/environment';
import type { StackDriverError } from '@libs/src/global-error-handler/stack-drive-error';
import type { AuthToken } from '@libs/src/models/auth-token-model';
import { decodeJwt } from '@libs/src/utilities/jwt.utilities';

@Injectable({ providedIn: 'root' })
export class LoggerService {
  constructor(
    @Inject(DOCUMENT) private readonly document: Document,
    private readonly http: HttpClient,
    private readonly localStorage: LocalStorageService,
    private readonly windowService: WindowRefService,
  ) {}

  logInfo(options: {
    anonymousId?: string;
    message: string;
    properties?: TrackParams['properties'];
  }): Observable<object | null> {
    if (!environment.production) {
      return of(null);
    }

    const decodedAuthToken = decodeJwt<AuthToken>(
      this.localStorage.getItem(AUTH_TOKEN_KEY),
    );
    const isSuperAdmin = !!decodedAuthToken?.superAdmin;
    const userId = decodedAuthToken?.superAdmin ?? decodedAuthToken?.accountId;
    return this.http.post('/__logs', {
      userId,
      anonymousId: options.anonymousId,
      message: options.message,
      properties: {
        isSuperAdmin,
        assumedUser: isSuperAdmin
          ? { _id: decodedAuthToken?.accountId }
          : undefined,
        ...options.properties,
      },
      referrer: this.getReferrer(),
      url: this.windowService.nativeWindow.location.href,
      userAgent: this.getUserAgent(),
    });
  }

  logError(options: { context?: Record<string, unknown>; message: string }) {
    if (!environment.production) {
      return;
    }

    const decodedAuthToken = decodeJwt<AuthToken>(
      this.localStorage.getItem(AUTH_TOKEN_KEY),
    );
    const userId = decodedAuthToken?.superAdmin ?? decodedAuthToken?.accountId;
    this.http
      .post('/__errors', {
        context: { ...options.context, authToken: decodedAuthToken },
        message: options.message,
        referrer: this.getReferrer(),
        url: this.windowService.nativeWindow.location.href,
        user: userId,
        userAgent: this.getUserAgent(),
      } as StackDriverError)
      .subscribe();
  }

  private getReferrer() {
    return this.document?.referrer;
  }

  private getUserAgent() {
    return this.windowService.nativeWindow?.navigator?.userAgent;
  }
}
